@import "variables.less";
@import "mixins.less";

.email-rapports {
    border-color: #6969db!important;    
}

.method-log {
    border-color: #0fc7e8!important;    
}

.the-overview {
    border-color: #acd9a0!important;    
}

.wall-of-images {
    border-color: #688f35!important;    
}

.all-in-one {
    border-color: #75217a!important;    
}

.timeseries {
    border-color: #d680e3!important;    
}

.section-stats-int {
    border-color: #e9436e!important;    
}

.overall-stats-int {
    border-color: #32d6a6!important;    
}

.section-stats {
    border-color: #c1d329!important;    
}

.overall-stats {
    border-color: #1f77b4!important;    
}


.index-icon {
    text-align: center;
    width: 100px;
}

#dash-index h3, #dash-index p {
	text-align: center;	
}
#dash-index div {
	text-align: center;	
}


#dash-index .plans {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
}
#dash-index {
	padding: 25px;	
}


.index-choise {
    border: 1px #ccc solid;
    margin: 20px;

}

div.methodlog b, div.methodlog i{
    color: #fff;
}

div.methodlog b.male{
    background-color: #a00b20;
}
div.methodlog i.male {
    background-color: #c56a77;
}

div.methodlog b.female {
    background-color: #0ba07b;
}
div.methodlog i.female{
    background-color: #63c2ab;
}

.darkgradient {
    
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
background: -moz-linear-gradient(top, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

width: 100%;
height: 73px;

color: #fff;
font-size: 14px;
padding: 4px 15px;
font-weight: bold;
}

.photowall {
    width: 100%!important;    
    height: 150px!important;
    padding: 0 0 0 0!important;
    border: 0!important;
}
.photowallfix {
    padding: 0 0 0 0;
}

*:focus {
    outline: none;
}

.abovegraph {
    margin-bottom: 0px;    
}


.makescrollable {
    overflow-x: auto;   
    overflow-y: hidden;    
}

.poweredby {
    font-size: 15px;
    color: #ccc;
    text-align: center;
    font-weight: 800;
}


.slick-slider {
	margin-bottom: 0px!important;
}
.carousel, .dashcarousel {
    overflow: hidden;    
} 

.dashcarousel {
    height: 200px; 
} 

.sourcepercentage {
    font-weight: bold;
}

.dashcenteredinfosmall {
    text-align: center;
    font-weight: 800;
    .serif;
    margin: 70px 0 20px 0;
    color: #BFBFBF;
    font-size: 10px;
}


.dashcenteredinfo {
    text-align: center;
    font-weight: 800;
    .serif;
    margin: 30px 0 20px 0;
    font-size: 20px;
}

.navbar-nav {
    background-color: rgba(255, 255, 255, 0.77)!important;
    text-align: right;
}
.navbar-nav>li>a {
    color: #000!important;
}

@media (min-width: 768px){
    .navbar-right {
        background-color: transparent!important;
    }
    .navbar-nav>li>a {
        color: #fff!important;
    }
}


.right {
    float: right;    
}

.btn-group button {
    font-size: 14px;
    padding: 7px 13px;
}

.selected {
    font-weight: bold!important;     
}

.headlines {
    padding-right: 0px;
    margin-bottom: 30px;
    margin-top: 0;
}

.headlines .glyphicon {
    margin-left: 7px;
}

.headline {
}
.headline p {
    font-size: 15px;
    color: #fff;
    margin: 0;
    font-weight: 800;
    text-align: center;
}

.dashboard-headlines {
    padding-right: 15px;   
    margin-bottom: 0px;
}

.dashboard-headlines .headlinecontent {
    width: 75%;
}

.dashboard-headlines .headlinemetadata {
    padding-top: 71px;
    width: 80px;
    float: right;
    text-align: right;
}

.headlinesfacebook {
    font-size: 10px;    
}



.dashboard-headlines .headlinecontent h3 {
    font-size: 15px;
}

.dashboard-headlines  .headlinecontent p {
    font-size: 12px;
}


@media (min-width: 400px){
    .dashboard-headlines .headlinecontent h3 {
        font-size: 20px;
    }    
    
    .dashboard-headlines  .headlinecontent p {
        font-size: 13px;
    }
}

@media (min-width: 700px){
    .dashboard-headlines .headlinecontent h3 {
        font-size: 30px;
    }    
    
    .dashboard-headlines  .headlinecontent p {
        font-size: 20px;
    }
}





.dashboard-headlines .headlinemetadata p {
    font-size: 14px;
}


.dashboard-headlines .headline {
    width: 25%;
}

.dashboard-headlines .alert-warning { 
    height: 153px; 
}

#headlineview {
    margin-top: 30px;
}
.headline {
    border: 1px solid #E2E1E1;
    padding: 0;
    width: 50%;
    float:left;
    height: 200px;
    overflow: hidden;
    opacity: 1;
}
.headlinecontent h5 {
    font-size: 10px;
    margin-top: 0;
    margin-bottom: 4px;
    font-weight: normal;
    color: #5B5B5B;

}
.headlinecontent h3 {
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 8px;

}
.headlinecontent p {
    font-size: 15px;
    line-height: 1.2;
    margin: 0;    
}
.headlinecontent {
    padding: 0px 0px 0px 10px;
    width: 50%;
    float:left;
    height: 197px;
    overflow: hidden;
}
.headlinecontent h3 span.glyphicon-link {
    color: #4F4F4F;
    font-size: 13px;
}
.headlinecontent img.linkicon {
    width: 12px;
}
/*
.whitehover {
    width: 100%;
    height: 100%;
}

.whitehover:hover {
    background-color: #fff;
    opacity: 0.85;
}
*/

.tooltipquestionmark {
    font-size: 10px;
}

.btn:focus {
    outline: 0px auto -webkit-focus-ring-color;

}

.showall {
    padding-bottom: 3px;
}

a:focus {outline: 0px; }

.graphdemo {
    margin-bottom: 18px;    
}

div.plans .label {
    line-height: 2.4;
}
.plans ul {
    margin-left: 0px;
}

.price { 
    text-align: center;    
}
.price > span.value{ 
    font-size: 34px; 
}

.signup {
    margin-top: 10px;    
}

.sorrystillloading {
    display: none;
    float:right;
    font-size: 12px;
    margin-top: 9px;
    margin-left: 10px;
}
.sorrysorrystillloading {
    display: none;
    float:right;
    font-size: 12px;
    margin-top: 9px;
    margin-left: 9px;
}

.slowloader {
    margin-left: 13px;
    margin-top: 1px;
    display: none;
    width: 26px;
}

#settings {
    padding: 20px 0 10px 0;
}

.active-trackers button {margin-bottom: 5px;}
a.expand img {
    margin-right: 4px;
    margin-left: 4px;    
    width: 18px;
}
.navbar-toggle {
    background-color: #fff;
    border-color: #ddd;
    margin-top: 13px;
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.3); /* IE */
  -moz-transform: scale(1.3); /* FF */
  -webkit-transform: scale(1.3); /* Safari and Chrome */
  -o-transform: scale(1.3); /* Opera */
  transform: scale(1.3); 
  margin-top: 8px;
  margin-right: 6px;
}

.site-heading {
    text-shadow: 0px 1px 124px #053E65!important;
}

div.subheading {
    text-shadow: 0px 0px 10px #053E65!important;
}
html {
}
.trackerpage {
    padding: 32px!important;    
}

.currentcollector {
    background-color: #F7F7F7;    
}

.plans p.centeredinfo {
    margin-top: 0;    
}

.plans {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    border-style: solid;
    border-color: #1F77B4;
    border-width: 10px;
    min-width: 230px;
}

.settings {
    padding-right: 30px;
    padding-left: 0px;    
}

h4 {
    padding: 15px 0 2px 0;    
}

.active-trackers {
    margin-top: 29px;
    height: 44px;
}

.centeredinfo {
    text-align: center;
    font-weight: 800;
    .serif;
    
}

.contracted {
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1200px){
.contracted {
    width: 1170px;
}}
@media (min-width: 992px){
.contracted {
    width: 970px;
}}
@media (min-width: 768px){
.contracted {
    width: 607px;
}}

.row {
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1200px){
.row {
    width: 1170px;
}}
@media (min-width: 992px){
.row {
    width: 970px;
}}
@media (min-width: 768px){
.row {
    width: 607px;
}}

#genderrep .row { 
    width: 100%; 
    padding-left: 20px;
    padding-right: 20px;
}

@media (min-width: 700px){
.dashcenteredinfo {
    font-size: 30px;
}
.dashcenteredinfosmall {
    font-size: 15px;
}

}


@media (min-width: 1200px){
.container {
    width: 100%;
}}
@media (min-width: 992px){
.container {
    width: 100%;
}}
@media (min-width: 768px){
.container {
    width: 100%;
}}


div.site-heading a { color: white; }


body {
	.serif;
	font-size: 20px;
	color: @gray-dark;
}

// -- Typography



p {
	line-height: 1.5;
	margin: 30px 0;
	.sans-serif;
	a {
		text-decoration: underline;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	.serif;
	font-weight: 800;
}

a {
	color: @gray-dark;
	&:hover,
	&:focus {
		color: @brand-primary;
	}
}

span.subheading > a { color: @white-faded; }

a img {
	&:hover,
	&:focus {
		cursor: zoom-in;
	}
}

blockquote {
	color: @gray;
	font-style: italic;
}

hr.small {
	max-width: 211px;
	margin: 21px auto 26px auto;
	border-width: 4px;
	border-color: white;
}

hr.smaller {
	max-width: 50px;
	margin: 15px auto 45px;
	border-width: 3px;
	border-color: white;
}

// Navigation

.navbar-custom {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 3;
	.sans-serif;
	.navbar-brand {
		font-weight: 800;
	}
	.nav {
		li {
			a {
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 800;
				letter-spacing: 1px;
			}
		}
	}
	@media only screen and (min-width: 200px) {
		background: transparent;
		border-bottom: 1px solid transparent;
		.navbar-brand {
			color: white;
			padding: 20px;
			&:hover,
			&:focus {
				color: @white-faded;
			}
		}
		.nav {
			li {
				a {
					color: white;
					padding: 20px;
					&:hover,
					&:focus {
						color: @white-faded;
					}
				}
			}
		}
	}
	@media only screen and (min-width: 1170px) {
		-webkit-transition: background-color 0.3s;
		-moz-transition: background-color 0.3s;
		transition: background-color 0.3s;
		/* Force Hardware Acceleration in WebKit */
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		&.is-fixed {
			/* when the user scrolls down, we hide the header right above the viewport */
			position: fixed;
			top: -61px;
			background-color: fade(white, 90%);
			border-bottom: 1px solid darken(white, 5%);
			-webkit-transition: -webkit-transform 0.3s;
			-moz-transition: -moz-transform 0.3s;
			transition: transform 0.3s;
			.navbar-brand {
				color: @gray-dark;
				&:hover,
				&:focus {
					color: @brand-primary;
				}
			}
			.nav {
				li {
					a {
						color: @gray-dark;
						&:hover,
						&:focus {
							color: @brand-primary;
						}
					}
				}
			}
		}
		&.is-visible {
			/* if the user changes the scrolling direction, we show the header */
			-webkit-transform: translate3d(0, 100%, 0);
			-moz-transform: translate3d(0, 100%, 0);
			-ms-transform: translate3d(0, 100%, 0);
			-o-transform: translate3d(0, 100%, 0);
			transform: translate3d(0, 100%, 0);
		}
	}
}

// Header

div.subheading {
    // max-width: 576px;
   }

.intro-header {
	background-color: @gray; 
	background: no-repeat center center; 
	background-attachment: scroll;
	.background-cover;
	// NOTE: Background images are set within the HTML using inline CSS!

	.site-heading,
	.post-heading,
	.page-heading {
		padding: 100px 0 50px;
		color: white;
		@media only screen and (min-width: 768px) {
			padding: 100px 0;
		}
	}
	.site-heading,
	.page-heading {
		text-align: center;
		h1 {
			margin-top: 0;
			font-size: 60px;
		}
		.subheading {
			font-size: 25px;
			line-height: 1.1;
			display: block;
			.sans-serif;
			font-weight: 300;
			margin: 10px 0 0;
		}
		@media only screen and (min-width: 768px) {
			h1 {
				font-size: 80px;
			}
			.subheading {
    			font-size: 28px;
		    }
		}
	}
	.post-heading {
		h1 {
			font-size: 35px;
			.serif;
		}
		.subheading,
		.meta {
			line-height: 1.1;
			display: block;
		}
		.subheading {
			.sans-serif;
			font-size: 24px;
			margin: 10px 0 30px;
			font-weight: 600;
		}
		.meta {
			.serif;
			font-style: italic;
			font-weight: 300;
			font-size: 20px;
			a {
				color: white;
			}
		}
		@media only screen and (min-width: 768px) {
			h1 {
				font-size: 55px;
				.serif;
			}
			.subheading {
				font-size: 30px;
			}
		}
	}
}

// Post Preview Pages

div.teammember > h2 {
    font-size: 24px;
    }

.post-preview {
	  
		color: @gray-dark;
		&:hover,
		&:focus {
			text-decoration: none;
			/*color: @brand-primary;*/
		}
		> .post-title {
			font-size: 30px;
			margin-top: 45px;
			margin-bottom: 10px;
		}
		> .post-subtitle {
			margin: 50px 0 40px 0;
			font-weight: 300;
			.sans-serif;
		
	}
	> .post-meta {
		color: @gray;
		font-size: 18px;
		font-style: italic;
		margin-top: 0;
		> a {
			text-decoration: none;
			color: @gray-dark;
			&:hover,
			&:focus {
				color: @brand-primary;
				text-decoration: underline;
			}
		}
	}
	@media only screen and (min-width: 768px) {
		> a {
			> .post-title {
				font-size: 36px;
			}
		}
	}
}

// Sections

.section-heading {
	font-size: 36px;
	margin-top: 60px;
	font-weight: 700;
}

.caption {
	text-align: center;
	font-size: 14px;
	padding: 10px;
	font-style: italic;
	margin: 0;
	display: block;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

footer {
	padding: 10px 10px;
	.list-inline {
		margin: 0;
		padding: 0;
	}
	.copyright {
		font-size: 20px;
		text-align: center;
		margin-bottom: 30px;
		margin-top: 30px;
	}
}

// Contact Form Styles

.floating-label-form-group {
	font-size: 14px;
	position: relative;
	margin-bottom: 0;
	padding-bottom: 0.5em;
	border-bottom: 1px solid @gray-light;
	input,
	textarea {
		z-index: 1;
		position: relative;
		padding-right: 0;
		padding-left: 0;
		border: none;
		border-radius: 0;
		font-size: 1.5em;
		background: none;
		box-shadow: none !important;
		resize: none;
	}
	label {
		display: block;
		z-index: 0;
		position: relative;
		top: 2em;
		margin: 0;
		font-size: 0.85em;
		line-height: 1.764705882em;
		vertical-align: middle;
		vertical-align: baseline;
		opacity: 0;
		-webkit-transition: top 0.3s ease,opacity 0.3s ease;
		-moz-transition: top 0.3s ease,opacity 0.3s ease;
		-ms-transition: top 0.3s ease,opacity 0.3s ease;
		transition: top 0.3s ease,opacity 0.3s ease;
	}
	&::not(:first-child) {
		padding-left: 14px;
		border-left: 1px solid @gray-light;
	}
}

.floating-label-form-group-with-value {
	label {
		top: 0;
		opacity: 1;
	}
}

.floating-label-form-group-with-focus {
	label {
		color: @brand-primary;
	}
}

form .row:first-child .floating-label-form-group {
	border-top: 1px solid @gray-light;
}

// Button Styles

.btn {
	.sans-serif;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 800;
	letter-spacing: 1px;
	border-radius: 0;
	padding: 7px 25px;
}

.btn-lg {
	font-size: 16px;
	padding: 25px 35px;
}

.btn-default {
	&:hover,
	&:focus {
		background-color: @brand-primary;
		border: 1px solid @brand-primary;
		color: white;
	}
}

// Pager Styling

.pager {

	margin: 20px 0 20px;

	li {
		> a,
		> span {
			.sans-serif;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 800;
			letter-spacing: 1px;
			padding: 15px 25px;
			background-color: white;
			border-radius: 0;
		}

		> a:hover,
		> a:focus {
			color: white;
			background-color: @brand-primary;
			border: 1px solid @brand-primary;
		}
	}

	.disabled {
		> a,
		> a:hover,
		> a:focus,
		> span {
			color: @gray;
			background-color: @gray-dark;
			cursor: not-allowed;
		}
	}
}

// -- Highlight Color Customization

::-moz-selection {
	color: white;
	text-shadow: none;
	background: @brand-primary;
}

::selection {
	color: white;
	text-shadow: none;
	background: @brand-primary;
}

img::selection {
	color: white;
	background: transparent;
}

img::-moz-selection {
	color: white;
	background: transparent;
}

body {
	webkit-tap-highlight-color: @brand-primary;
}